	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/10/2020 07:04
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-zoom:before { content: "\f100"; }
.flaticon-tick:before { content: "\f101"; }
.flaticon-shopping-bags:before { content: "\f102"; }
.flaticon-worldwide:before { content: "\f103"; }
.flaticon-phone-call:before { content: "\f104"; }
.flaticon-diamond:before { content: "\f105"; }
.flaticon-check:before { content: "\f106"; }
.flaticon-info:before { content: "\f107"; }
.flaticon-right-arrow:before { content: "\f108"; }
.flaticon-up-arrow:before { content: "\f109"; }
.flaticon-cancel:before { content: "\f10a"; }
.flaticon-bell:before { content: "\f10b"; }
.flaticon-loupe:before { content: "\f10c"; }
.flaticon-bell-1:before { content: "\f10d"; }
.flaticon-forward:before { content: "\f10e"; }
.flaticon-email:before { content: "\f10f"; }
.flaticon-desktop:before { content: "\f110"; }
.flaticon-collaboration:before { content: "\f111"; }
.flaticon-close:before { content: "\f112"; }
.flaticon-eye:before { content: "\f113"; }
.flaticon-visibility:before { content: "\f114"; }
.flaticon-play:before { content: "\f115"; }
.flaticon-phone-call-1:before { content: "\f116"; }
.flaticon-pin:before { content: "\f117"; }
.flaticon-chat:before { content: "\f118"; }
.flaticon-user:before { content: "\f119"; }
.flaticon-telephone:before { content: "\f11a"; }
.flaticon-book:before { content: "\f11b"; }
.flaticon-envelope:before { content: "\f11c"; }
.flaticon-refresh:before { content: "\f11d"; }
.flaticon-tag:before { content: "\f11e"; }
.flaticon-gear-option:before { content: "\f11f"; }
.flaticon-trash:before { content: "\f120"; }
.flaticon-user-1:before { content: "\f121"; }
.flaticon-user-2:before { content: "\f122"; }
.flaticon-web-hosting:before { content: "\f123"; }
.flaticon-hosting-services:before { content: "\f124"; }
.flaticon-hosting:before { content: "\f125"; }
.flaticon-login:before { content: "\f126"; }
.flaticon-renewable-energy:before { content: "\f127"; }
.flaticon-phone:before { content: "\f128"; }
.flaticon-news-paper:before { content: "\f129"; }
.flaticon-cloud-computing:before { content: "\f12a"; }
.flaticon-cloud-hosting:before { content: "\f12b"; }
.flaticon-hosting-1:before { content: "\f12c"; }
.flaticon-hosting-services-1:before { content: "\f12d"; }
.flaticon-domain:before { content: "\f12e"; }
.flaticon-database:before { content: "\f12f"; }