@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@500&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans",sans-serif;
  position: relative;
  background-color: #fff
}
   
.pt-40 {
  padding-top: 40px
}  
.pt-100 {
  padding-top: 100px
}    

.margin-minus-box {
  margin-top: -170px
}
p {
  margin: 0;
  color: #161616
}
a {
  color: #2647c8;
  text-decoration: none
}
a:active,
a:hover {
  text-decoration: none;
  color: #2647c8
}
i:before {
  margin: 0!important
}
.max-700 {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto
} 
 
.btn {
  padding: 15px 35px;
  border-radius: 3px;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  border: 2px solid #f7bf15;
  transition: all .3s linear
}
.btn:active,
.btn:focus {
  box-shadow: none;
  border: 2px solid rgba(255,255,255,0)
}
.btn:hover {
  color: #2647c8
}
 
.btn-blue:hover {
  color: #fff
}
.btn-blue:active,
.btn-blue:focus {
  border: 2px solid rgba(255,255,255,.29)
}
.btn-gradient {
  background: linear-gradient(160deg,#f7bf15,#f7bf15,#f7bf15 ,#e0a200,#e0a200,#f7bf15);
  background-size: 300% 100%;
  transition: all .4s ease-in-out;
  padding: 10px 20px;
  font: 2500 25px/1 "DM Sans",sans-serif;
  height: auto
}
.btn-gradient:hover {
  background-position: 100% 0;
  transition: all .4s ease-in-out;
  color: #fff
}
.form-control {
  box-shadow: none
}
.form-control:active,
.form-control:focus {
  box-shadow: none
}
.section-title {
  max-width: 670px;
  text-align: center;
  margin: auto;
  margin-bottom: 60px
}
.section-title small {
  color: #2647c8;
  font-size: 17px;
  text-transform: capitalize;
  margin-bottom: 15px;
  display: block
}
.section-title h2 {
  color: #000c35;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 25px
}
.section-title p {
  font-size: 16px;
  font-weight: 400
}
.section-title p a {
  color: #2647c8;
  border-bottom: 1px solid #2647c8
}
.section-title.section-title-lg {
  max-width: 820px
}
.section-title-two h2 {
  font-size: 40px
}
.section-title-left {
  max-width: 100%;
  text-align: left
}
.sub-section-title h3 {
  font-size: 28px;
  color: #1c2522;
  font-weight: 500;
  margin-bottom: 15px
}
.sub-section-title p {
  font-size: 17px;
  font-weight: 400
}
.status-blue {
  background-color: rgba(12,89,231,.2)
}
.status-orange {
  background-color: rgba(241,24,16,.2)
}
.status-green {
  background-color: rgba(12,231,187,.2)
}
 
.preloader {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: visible;
  z-index: 99999;
  top: 0;
  left: 0
} 
.custom-container-fluid {
  max-width: 1470px
}  
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  display: flex;
  background-color: #011a53;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px
}  
@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
  .main-nav-two .navbar-option-icon span {
    color: #2647c8;
    background: 0 0
  }
}
.main-nav-two .navbar-option-icon .shopping-cart-tooltip {
  background: linear-gradient(90deg,#2647c8 0,#142d99 42%,#02126a 100%);
  color: #fff!important;
  -webkit-text-fill-color: #fff;
  padding: 0 7px
}
.main-nav-two .navbar-light .navbar-brand:nth-child(2) {
  display: none
}
.mean-bar {
  box-shadow: 0 11px 35px 0 rgba(196,196,196,.21);
  border-top: 1px solid #607efc
}
.mobile-nav {
  background-color: #011a53
}
.header-right {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%)
}
@media (min-width:768px) {
  .header-right {
    right: 40px
  }
}
@media (max-width:767px) {
  .header-right {
    right: 10px;
    top: 50%
  }
}
.mobile-nav.mean-container .mean-nav {
  float: left;
  width: 100%;
  margin-top: 66px
}
.mobile-nav.mean-container .mean-nav ul li a {
  color: #000c35;
  border-top: 1px solid #d8d8d8;
  font-size: 16px
}
.mobile-nav.mean-container .mean-nav ul li a.active {
  color: #2647c8;
  font-weight: 500
}
.mobile-nav.mean-container .mean-nav ul li ul li a {
  color: #000c35
}
.mobile-nav.mean-container .mean-nav ul li ul li a.active {
  color: #2647c8
}    
.domain-search {
  max-width: 700px;
  margin: auto
}
.domain-search .form-group {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px 10px 10px 30px;
  border-radius: 3px;
  margin-bottom: 30px
}
.domain-search .form-group .form-control {
  flex: 1;
  -ms-flex: 1;
  max-width: 100%;
  width: 100%;
  border: 0;
  padding: 10px 15px;
  color: #161616;
  font-size: 16px
}
.domain-search .form-group .form-control::-moz-placeholder {
  color: #757575;
  font-size: 16px
}
.domain-search .form-group .form-control::placeholder {
  color: #757575;
  font-size: 16px
}
.domain-search .form-group .form-control:active,
.domain-search .form-group .form-control:focus {
  outline: 0;
  box-shadow: none
}
.domain-search .form-group-pill {
  border-radius: 70px
}
.domain-search-category ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px
}
.domain-search-category ul li {
  padding: 0 10px;
  margin-bottom: 20px;
  font-size: 16px
}
.domain-search-category ul li a {
  display: block;
  padding: 10px 20px;
  border-radius: 30px;
  color: #fff;
  transition: all .3s linear;
  border: 2px solid rgba(255,255,255,.29)
}
.domain-search-category ul li a:hover {
  background-color: #3d55be;
  box-shadow: inset 0 0 3px 0 rgba(2,18,106,.1)
}
.domain-search-category ul li a.active {
  background-color: #3d55be;
  box-shadow: inset 0 0 3px 0 rgba(2,18,106,.1)
}    
.footer-upper {
  background: #07123a
}
.footer-content-item {
  margin-bottom: 20px
}
.footer-logo {
  max-width: 120px
}
.footer-logo a {
  display: block
}
.footer-details {
  margin-top: 30px
}
.footer-details p {
  font-size: 15px;
  color: #d8d8d8
}
.footer-content-title h3 {
  font-size: 20px;
  color: #fff
}
.footer-list li {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative
}
.footer-list li:last-child {
  margin-bottom: 0
}
.footer-list li a {
  color: #d8d8d8;
  transition: all .3s linear
}
.footer-list li:before {
  content: "\ea50";
  font-family: boxicons!important;
  color: #d8d8d8;
  left: 0;
  position: absolute;
  transition: all .3s linear
}
.footer-list li:hover a {
  color: #fff
}
.footer-list li:hover:before {
  color: #fff
}
.footer-lower {
  background: #0a1e64;
  padding: 20px 0
}
.footer-lower-item {
  padding: 10px 15px
}
.footer-lower-content h3 {
  font-size: 18px;
  color: #fff
}
.footer-social-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px
}
.footer-social-list li {
  flex: 0 0 30px;
  -ms-flex: 0 1 auto;
  max-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 7px;
  transform: translateY(0);
  transition: all .2s ease-in-out
}
.footer-social-list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 16px
}
.footer-social-list li:hover {
  transform: translateY(-3px)
}
.social-btn.social-btn-fb a {
  background: #3b5998;
  color: #fff
}
.social-btn.social-btn-tw a {
  background: #55acee;
  color: #fff
}
.social-btn.social-btn-ins a {
  background: #3f729b;
  color: #fff
}
.social-btn.social-btn-pin a {
  background: red;
  color: #fff
}
.social-btn.social-btn-yt a {
  background: #c80000;
  color: #fff
}
.social-btn.social-btn-ld a {
  background: #2867b2;
  color: #fff
}
.footer-lower-item-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center
}
.footer-lower-item-right p {
  color: #d8d8d8;
  font-size: 15px
}
.footer-lower-text {
  margin-right: 25px;
  padding: 10px 0
}
.footer-lower-text p {
  margin-bottom: 10px
}
.footer-lower-text p:last-child {
  margin-bottom: 0
}
.footer-text-copy a {
  color: #b6c5ff
}
.footer-text-copy a:hover {
  text-decoration: underline
}
.footer-text-gen a {
  color: #fff;
  text-decoration: underline
}
 
.header-bg-shape-two {
  position: relative;
  height: auto;
  background: url(../images/header-bg-shape-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top
}
.header-width {
  max-width: 540px;
  padding: 35px 0;
  width: 100%
}
.header-main-content-two {
  position: relative;
  z-index: 1
}
.header-main-content-two h4 {
  color: #2647c8;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px
}
.header-main-content-two h1 {
  font-size: 53px;
  font-weight: 600;
  color: #f8f8f8;
  margin-bottom: 30px;
  width: 100%
}
.header-main-content-two h1 small,
.header-main-content-two h1 span {
  font-size: 40px
}
.header-main-content-two p {
  font-size: 18px;
  color: #161616;
  margin-bottom: 40px
}
.header-main-content-two .header-content-list {
  margin-bottom: 50px;
  width: 100%
}
.header-content-image {
  max-width: 720px
}
.header-content-image img {
  width: 100%
}
.domain-search-section-two {
  position: relative
}
.domain-search-section-two .section-title {
  color: #000c35
}
.domain-search-two .form-group {
  background: #f9f9f9;
  border: 1px solid #2647c8
}
.domain-search-two .form-group .form-control {
  color: #000c35;
  background: 0 0;
  padding: 5px
}
.domain-search-two .form-group .form-control::-moz-placeholder {
  color: #757575
}
.domain-search-two .form-group .form-control::placeholder {
  color: #757575
}
.domain-search-two .form-group .input-group-append {
  padding-right: 15px;
  border-left: 1px solid #d8d8d8
}
.domain-search-two .form-group .input-group-append select {
  cursor: pointer
}
.domain-search-two .domain-search-category ul li a {
  border-radius: 3px;
  border-width: 1px;
  border-color: #c8d1f1;
  color: #2647c8
}
.domain-search-two .domain-search-category ul li a:hover {
  background: rgba(200,209,241,.29);
  color: #2647c8
}
.domain-search-two .domain-search-category ul li a.active {
  background: rgba(200,209,241,.29)
}  
.pricing-header-title p {
  font-size: 15px;
  font-weight: 400
}
.cloud-shape-bg-fixed {
  position: absolute;
  width: 100%;
  height: 600px;
  top: 0;
  overflow: hidden
}
.cloud-shape-bg-fixed:before {
  content: "";
  background-color: #ebeefa;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100px;
  left: 0
}
.cloud-shape-top {
  padding-top: 18%
}
.cloud-shape-top .cloud-shape-image {
  top: 0
}
.header-page {
  padding: 180px 0 100px
}
.header-page-content {
  padding: 0
}
.header-page-content h1 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 15px
}
.header-page-content p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px
}
.header-page-content .section-button li {
  margin-bottom: 0
}
.header-page-image img {
  width: 100%;
  padding-top: 10%
}
.header-padding {
  padding: 240px 0 240px
}         
.modal-content {
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0,0,0,.3)
}
.btn-close {
  position: absolute;
  top: 20px;
  right: 25px;
  background: 0 0;
  color: #333;
  font-size: 30px;
  border: none;
  cursor: pointer;
  transition: color .3s ease
}
.btn-close:hover {
  color: #ff5a5f
}
.modal-title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 26px;
  color: #333;
  text-align: center
}
.modal-search-input {
  width: 100%;
  padding: 14px 20px;
  margin-bottom: 25px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 18px
}
.car-list {
  max-height: 350px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0
}
.car-item {
  display: flex;
  align-items: flex-start;
  padding: 12px 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color .2s ease
}
.car-item:hover {
  background-color: #f0f0f0
}
.brand-logo {
  width: 60px;
  height: auto;
  margin-right: 20px
}
.car-info {
  display: flex;
  flex-direction: column
}
.car-name {
  font-size: 20px;
  color: #333
}
.car-subtext {
  font-size: 14px;
  color: #777
}
.car-year {
  font-size: 16px;
  color: #555
}
.no-results {
  text-align: center;
  padding: 20px;
  color: #999;
  font-size: 18px
}
.loading {
  text-align: center;
  padding: 15px;
  font-size: 18px;
  color: #555
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensure the modal is on top of other elements */
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0,0,0,.3);
}
@media (max-width:768px) {
  .modal-content {
    padding: 20px;
    max-width: 95%
  }
  .brand-logo {
    width: 50px;
    margin-right: 15px
  }
  .car-name {
    font-size: 18px
  }
  .car-subtext,
  .car-year {
    font-size: 14px
  }
  .modal-title {
    font-size: 22px
  }
  .modal-search-input {
    font-size: 16px
  }
}

.battery-page {
  padding: 60px 0;
}

.page-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.car-year {
  font-size: 18px;
  margin-bottom: 40px;
}

.battery-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.battery-card {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
}

.battery-card h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.battery-info {
  font-size: 16px;
  margin-bottom: 10px;
}

.battery-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Contact Modal Styles */

.contact-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.btn-whatsapp {
  background-color: #25D366;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
}

.btn-whatsapp:hover {
  background-color: #1DA851;
}

.btn-phone {
  background-color: #34A853;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
}

.btn-phone:hover {
  background-color: #2C8C46;
}
.payment-note {
  color: #d9534f; /* Vermelho para chamar atenção */
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 14px;
}
.footer-bg{
  padding-top: 100px;
}